$accent-color: #752cd1;
$accent-border: #4d0f9b;
$secondary-color: #414141;
$third-color: #303030;
$fourth-color: #acacac;
$firth-color: #f5f5f5;
$disabled-color: #383838;
$disabled-border: #424242;
$dashboard-subelement: #575757;
$font--primary: 'Fauna';
$font--secondary: 'Fauna';

@mixin inputForm {
  border: none;
  outline: none;
  border-radius: 3px;
  padding: 0 1rem;
}

