@import "../../../helpers/styleVariables/variables.scss";

.players {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  .playerContent {
    display: flex;
    justify-content: space-between;
    width: 43%;
    align-items: center;
    padding: 2%;
    .player {
      margin-top: 9px;
      height: 40px;
      width: 40px;
      border-radius: 50px;
      // background-color: $accent-color;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 50px;
      }
    }
    .playerInfo {
      width: 80%;
      color: white;
      &:first-child {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        .healthbar {
          justify-content: flex-end;
          .life {
            justify-content: flex-end;
            // background-color: #487deb;
          }
        }
      }
    }
    .chat {
      border-color: #3061d0 !important;
    }
    .chouette {
      border-color: #ac7de6 !important;
    }

    .grenouille {
      border-color: #3ccba0 !important;
    }

    .hibou {
      border-color: #ef9866 !important;
    }

    .lapin {
      border-color: #efd166 !important;
    }
    .healthbar {
      height: 12px;
      width: 100%;
      background-color: transparent !important;
      border: solid 2px;
      display: flex;
      align-items: center;
      border-radius: 50px;

      .life {
        transition: all 0.2s;
        padding: 6px;
        display: flex;
        align-items: center;
        height: 90%;
        // width: 100%;
        border-radius: 50px;
        // background-color: #00e0a3;
      }
    }
    .playerNameLife {
      display: flex;
      width: 100%;
      justify-content: space-between;
      p {
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
  }
  .timerManche {
    margin-top: 5px;
    color: $firth-color;
    .timer {
      text-align: center;
      font-size: 1.5em;
    }
    .manche {
      text-align: center;
    }
  }
}
