@font-face {
  font-family: "Fauna Pro ExtLt";
  src: url("FaunaPro-ExtraLight.woff2") format("woff2"), url("FaunaPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fauna Pro";
  src: url("FaunaPro-Bold.woff2") format("woff2"), url("FaunaPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fauna Pro";
  src: url("FaunaPro-Regular.woff2") format("woff2"), url("FaunaPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
