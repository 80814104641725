@import "../../../helpers/styleVariables/variables.scss";

.pause-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 500;
  background: rgba(13, 13, 13, 0.38);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  .pause {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 80%;
    width: 80%;
    .cards {
      display: flex;
      justify-content: center;
      margin: 20px 0px;
      height: 50%;
      button.active {
        box-shadow: 0px 0px 30px 5px rgba(118, 44, 209, 0.61);
        border-radius: 6%;
        img {
          box-shadow: unset !important;
        }
      }
      button.disabled {
        filter: grayscale(1);
        box-shadow: unset !important;
        img {
          box-shadow: unset !important;
        }
      }
      button {
        margin: 20px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        height: 100% !important;
        background-color: transparent;
        img {
          transition: all 0.3s;
          height: 100%;
          width: 100%;
          object-fit: contain;
          border-radius: 6%;
          &:hover {
            transition: all 0.3s;
            color: $accent-color;
            box-shadow: 0px 0px 30px 5px rgba(118, 44, 209, 0.61);
          }
        }
      }
    }
    h3 {
      color: white;
      font-size: 1.4vw;
      text-transform: uppercase;
      font-weight: 100;
    }
    p {
      margin-top: 30px;
      color: white;
    }
  }
  .btnSkew {
    margin-top: 30px;
    width: 40%;
  }
}
