@import './helpers/styleVariables/variables.scss';
@import 'https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}
#root {
  width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #575757;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $accent-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $accent-color;
}

body {
  background: black;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;

  > iframe {
    display: none;
  }
}

.fauna {
  font-family: 'Fauna Pro';
}

iframe {
  display: none;
}

.card__title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 30px;
}

.App {
  > iframe {
    display: none;
  }
}

// INPUT FORM

.input {
  width: 80%;
  margin-bottom: 20px;
  input {
    width: 100%;
    padding: 10px 10px;
    &:focus {
      outline: solid 2px $accent-color;
      box-shadow: 0px 0px 15px 0px rgba(117, 44, 209, 1);
    }
  }
  p {
    margin-bottom: 7px;
    color: white;
  }
}

.chat {
  background-color: #3061d0;
}

.chouette {
  background-color: #ac7de6;
}

.grenouille {
  background-color: #3ccba0;
}

.hibou {
  background-color: #ef9866;
}

.lapin {
  background-color: #efd166;
}
