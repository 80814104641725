@import "../../helpers/styleVariables/variables.scss";

.main-dash {
  height: 100vh;
  width: 100%;
  padding: 60px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .part-dashboard {
    width: 32%;
    height: 100%;
    background-color: $third-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    padding: 20px;
  }
  .header-dash {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: flex-end;
    .info-joueur {
      color: white;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .btnSkew {
        height: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4%;
        position: relative;
        z-index: 2;
      }
      .pseudo-joueur {
        margin-right: 2%;
      }
      .img-joueur {
        object-fit: contain;
        height: 40px;
        width: 40px;
        border-radius: 50px;
      }
      .pseudo-joueur {
        display: flex;
        align-items: center;
      }
    }
  }
  .main-dashboard-container {
    height: 43%;
    width: 100%;
    background-color: $third-color;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .vs-element {
      width: 90%;
      height: 100%;
      position: relative;
      margin-bottom: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .you,
      .ennemy {
        top: 11.5vh;
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 70%;
        background: $dashboard-subelement;
        padding: 30px;
        img {
          height: 90px;
          width: 90px !important;
          object-fit: contain;
          width: auto;
          background-color: #414141;
          border-radius: 50px;
        }
        p {
          color: white;
          &:first-child {
            font-weight: 500;
            font-size: 2vw;
          }
          &:last-child {
            font-size: 1vw;
          }
        }
        &:first-child {
          img {
            margin-right: 10px;
          }
        }
        &:first-child {
          text-align: left;
        }
        &:last-child {
          justify-content: flex-start;
          text-align: right;
          flex-direction: row-reverse;
          img {
            margin-left: 15px;
          }
        }
      }
      .you {
        left: 0;
        clip-path: polygon(0 0, 58% 0, 38% 100%, 0 99%);
        border-radius: 10px 0px 0px 10px;
      }
      .ennemy {
        right: 0;
        clip-path: polygon(62% 0, 100% 0%, 100% 100%, 42% 100%);
        border-radius: 0px 10px 10px 0px;
      }
      .winner {
        background-color: $accent-color !important;
      }
    }
    .skin-player {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 50%;
    }
  }
  .other-info {
    height: 43%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

h3.dashboard-subtitle {
  font-weight: 500;
  color: white;
  font-size: 1.3vw;
  margin-bottom: 3%;
}
