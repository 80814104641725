.App {
  &.playing {
    background: url(https://haltogame.com/img/bg-light.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    .App__wrapper {
      background: rgb(0, 0, 0);
      background: linear-gradient(90deg, rgb(0 1 5 / 90%) 0%, rgb(0 1 5 / 70%) 35%, rgb(0 0 5 / 70%) 65%, rgb(0 1 5 / 90%) 100%);
    }
  }
  &__wrapper {
    z-index: 2;
  }

  &__openhelp {
    position: fixed;
    right: 2rem;
    top: 2rem;
    width: 2rem;
    cursor: pointer;
  }

  &__openauth {
    position: fixed;
    left: 2rem;
    top: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      width: 2rem;
    }

    p {
      font-size: 0.8rem;
      margin-left: 2rem;
      font-weight: 300;

      span {
        font-weight: 500;
      }
    }
  }

  .not-playable {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem;

    &__title {
      font-size: 3rem;
      text-align: center;
      font-weight: 600;
    }

    &__text {
      font-size: 1.5rem;
      text-align: center;
      margin: 2rem 0;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .version-control {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 1rem;
    opacity: 0.5;
    font-weight: 200;
    font-size: 0.8rem;
  }
}
