@import '../../../helpers/styleVariables/variables.scss';

.stats {
  width: 32%;
  height: 100%;
  .stats-subcontainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .win-stats {
      // padding-top: 3%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 40%;
      .win-percent {
        width: 70%;
        .jauge {
          border-radius: 4px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-direction: column;
          color: white;
          &.positive {
            background-color: rgb(90, 194, 116);
          }
          &.negative {
            background-color: rgb(194, 90, 90);
          }

          span {
            font-weight: 600;
            font-size: 1.5vw;
          }
        }
      }
      .loose-win-stats {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        .win-loose {
          height: 45%;
          width: 90%;
          background-color: $accent-color;
          border-radius: 4px;
          color: white;
          display: flex;
          padding: 10px;
          align-items: center;
          justify-content: center;
          font-size: 0.8vw;
          span {
            font-weight: 700;
            padding-right: 4%;
          }
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
    .statistiques {
      margin-top: 6%;
      height: 30%;
      .win-loose-time {
        margin-bottom: 2%;
      }
      .subelement {
        background-color: #575757;
        color: white;
        border-radius: 7px;
        padding: 3%;
      }
    }
  }
}

@keyframes progress-1 {
  from {
    stroke-dashoffset: 360;
  }
  to {
    stroke-dashoffset: 120;
  }
}
