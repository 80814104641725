.admin {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  background-color: black;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__container {
    padding: 5rem 0;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    section {
      margin-bottom: 5rem;
      width: 100%;

      h3 {
        color: white;
        font-size: 3rem;
        margin-bottom: 2rem;
      }
    }
  }

  &__bulkDelete {
    background-color: #ff0033;
    color: white;
    margin-bottom: 1rem;
  }

  &__setMatchmaking {
    color: white;
    transition: background-color 0.3s ease-in-out;

    &.false {
      background-color: #ff0033;
    }

    &.true {
      background-color: #0fff95;
    }
  }

  button {
    border: none;
    padding: 1rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    width: fit-content;
  }

  .ranks {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
    height: 80vh;

    .rank {
      width: 100%;
      background-color: white;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        width: 25%;
        color: black;
      }
    }
  }
}