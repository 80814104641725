@keyframes floating {
  0% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0);
  }
}

.floatingItems {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-color: #181818;

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .floating {
      animation: floating 3s ease-in-out infinite alternate;
    }

    .moving {
      transition: transform 0.3s ease-in-out;
    }

    img {
      position: absolute;
    }

    .planet {
      right: 0;
      bottom: 0;
      width: 45vw;
    }

    .triangle {
      right: 20vw;
      top: 10vh;
      animation-delay: 1s;
    }

    .planet2 {
      left: 0;
      bottom: 0;
    }

    .triangle2 {
      left: 20vw;
      top: 40vh;
      animation-delay: 1s;
    }
  }
}
