@import '../../../helpers/styleVariables/variables.scss';

@mixin flexmid {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin inputForm {
  border: none;
  outline: none;
  border-radius: 3px;
  padding-left: 5px;
}

.auth {
  position: fixed;
  top: 0;
  z-index: 5;
  height: 100vh;
  width: 100vw;
  @include flexmid();
  color: #f5f5f5;

  &__bg {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    z-index: 3;
    height: 100vh;
    width: 100vw;
  }

  &__wrapper {
    z-index: 5;
    padding: 4rem;
    border-radius: 5px;
    width: 100%;
    max-width: 500px;
    background-color: $third-color;
    opacity: 0;
    transform: translateY(20px);
    animation: fade-bottom 0.3s ease-in-out forwards;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 2rem;
      text-align: center;
      width: 100%;
    }

    input, button {
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 44px;
      @include inputForm;
      margin-bottom: 10px;

      &.half {
        width: 49%;
      }

      &.form__wrapper__mdp {
        &.match {
          border: 3px solid $accent-color;
          color: $accent-color;
        }
        
      }
    }

    input {
      border: 3px solid white;
      transition: border 0.2s ease-in-out;
    }

    &__close {
      margin-right: 5px;
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 30px;
      object-fit: contain;
      cursor: pointer;
    }

    button {
      margin: 2rem 0;
    }

    &__switch {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.7);
      width: 100%;
      text-align: center;
    }

    &__bonjour {
      font-size: 1.5rem;
      text-align: center;
      width: 100%;

      span {
        font-weight: 500;
      }
    }

    &__rank {
      margin: 2rem 0;
      text-align: center;

      span {

        font-weight: 500;
      }
    }

    &__logout {
      width: 100%;
      cursor: pointer;
      text-align: center;
    }
  }
}