@import "../../../helpers/styleVariables/variables.scss";

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes animCharacter {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes openIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@mixin flexmid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.joueur {
  color: $firth-color;
}

#vs {
  color: white;
  font-size: 6rem;
}

.roomWaiting {
  height: 100vh;
  @include flexmid();
  width: 100%;

  &__wrapper {
    display: flex;
    width: 100%;
    max-width: 600px;
    justify-content: space-between;
    max-width: 1000px;
  }

  &__player {
    height: 350px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    &__notready,
    &__ready {
      margin: 1rem 0;
      display: flex;
      align-items: center;
      color: white;

      img {
        margin: 0.5rem;
        height: 1rem;
      }
    }

    &__character {
      margin-bottom: 10%;
      height: 200px;
      width: 200px;
      // background-color: black;
      border-radius: 50%;
      position: relative;
      opacity: 0;
      transform: scale(0);
      animation: openIn 0.3s ease-in-out forwards;

      img {
        height: 150%;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transform: translateY(100px);
        animation: animCharacter 0.5s ease-in-out forwards;
      }
    }

    &__setReady {
      align-self: flex-end;
    }
  }

  &__versus {
    margin: 0 40px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;

    &__roomID {
      font-size: 2.5rem;
    }

    .extraLarge {
      width: 200%;
      margin: 2rem 0;
    }
    .waitPlayer {
      color: white;
    }
    &__link {
      z-index: 2;
    }

    &__copiedText {
      z-index: 0;
      background-color: #64ed61;
      padding: 10px;
      border-radius: 5px;
      color: white;
      opacity: 0;
      transform: translateY(-50px);
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

      &.visible {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
.character0 {
  transition: all 0.3s;
  background-color: #3061d0;
}

.character1 {
  transition: all 0.3s;
  background-color: #ac7de6;
}

.character2 {
  transition: all 0.3s;
  background-color: #3ccba0;
}

.character3 {
  transition: all 0.3s;
  background-color: #ef9866;
}

.character4 {
  transition: all 0.3s;
  background-color: #efd166;
}
