@import "../../helpers/styleVariables/variables.scss";

@mixin widthHeightPart {
  width: 27%;
  height: 100vh;
}
@mixin flexBetween {
  display: flex;
  justify-content: space-between;
}

@mixin playerLife {
  width: 100%;
  height: 7px;
  background-color: $accent-color;
}

.gamePart {
  width: 47%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mainContent {
  display: flex;
  height: 100vh;
  // background: url(../../assets/img/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.partLeft {
  width: 27%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  .contentPart {
    margin-top: 20px;
    margin-left: 20px;
    .textModeGame {
      margin-top: 30px;
      margin-bottom: 15px;

      color: $firth-color;
    }
    .roomID {
      margin-top: 20px;
      color: white;
    }
    .modeGame {
      font-family: "Fauna Pro";
      color: $firth-color;
      margin-bottom: 15px;
    }
    .modeConsist {
      color: #f5f5f5;
      font-size: 13px;
      font-weight: 100;
    }
  }
  .cards {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    max-height: 210px;
    .card {
      max-width: 150px;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 7px;
      color: white;
      font-size: 10px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        max-height: 170px;
      }
    }
  }
}

.partRight {
  @include widthHeightPart;
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  flex-direction: column;

  .information {
    position: absolute;
    right: 2.5rem;
    top: 2.1rem;
    width: 70px;
    .speaker {
      width: 40%;
      cursor: pointer;
    }
  }
  .controls {
    flex-direction: column;
    .inputControl {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .chevron {
      border: 1px solid #fff;
      padding: 1px 5px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      color: #fff;
      p {
        font-size: 0.8vw;
      }
    }
    .textChevron {
      font-size: 0.9rem;
      color: $firth-color;
    }
  }
}
.touchAnim {
  &.touched {
    background-color: rgb(168, 38, 38);
    animation: blink 0.3s ease-out forwards;
  }
  &.inflicted-chat {
    animation: blink 0.3s ease-out forwards;
    background-color: #3061d0;
  }

  &.inflicted-chouette {
    animation: blink 0.3s ease-out forwards;
    background-color: #ac7de6;
  }

  &.inflicted-grenouille {
    animation: blink 0.3s ease-out forwards;
    background-color: #3ccba0;
  }

  &.inflicted-hibou {
    animation: blink 0.3s ease-out forwards;
    background-color: #ef9866;
  }

  &.inflicted-lapin {
    animation: blink 0.3s ease-out forwards;
    background-color: #efd166;
  }
}

@keyframes blink {
  0% {
  }
  100% {
    background: transparent;
  }
}
