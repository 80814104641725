@import "../../helpers/styleVariables/variables.scss";

.current-game-stats-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 500;
  background: rgba(13, 13, 13, 0.38);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .current-game-stats {
    border-radius: 10px;
    // height: 60%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    img {
      height: 120px;
      width: 120px;
      border-radius: 100px;
      object-fit: contain;
      margin-bottom: 1%;
    }
    h1 {
      color: white;
      font-weight: 200;
      margin-bottom: 3%;
    }
    h1.chat {
      box-shadow: 0px 0px 10px 5px rgba(48, 96, 208, 0.451);
    }

    h1.chouette {
      box-shadow: 0px 0px 10px 5px rgba(173, 126, 231, 0.451);
    }

    h1.grenouille {
      box-shadow: 0px 0px 10px 5px rgba(60, 203, 160, 0.451);
    }

    h1.hibou {
      box-shadow: 0px 0px 10px 5px rgba(239, 152, 102, 0.451);
    }

    h1.lapin {
      box-shadow: 0px 0px 10px 5px rgba(239, 209, 102, 0.451);
    }
    h3 {
      color: white;
      margin-bottom: 3%;
    }
    .winner {
      padding: 15px 10px;
      transform: skew(15deg);
      border-radius: 5px;
      box-shadow: 0px 0px 15px 10px rgba(117, 44, 209, 0.44);
      &:first-child {
        transform: skew(-15);
      }
    }
  }
}
