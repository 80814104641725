@import "../../helpers/styleVariables/variables.scss";

.btnSkew {
  background-color: $accent-color;
  box-shadow: none;
  border: none;
  font-family: "Fauna Pro";
  transform: skew(20deg);
  color: white;
  padding: 20px;
  border-radius: 4px;
  border-bottom: solid 3px $accent-border;
  border-left: solid 3px $accent-border;
  box-shadow: 0px 5px 15px 0px rgba(117, 44, 209, 0.44);
  cursor: pointer;

  div {
    font-size: 1.1rem;
    text-transform: uppercase;
    transform: skew(-20deg);
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.42);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 1rem;
      margin-left: 0.5rem;
    }
  }
}

.btnSkew.disabled {
  background-color: $disabled-color;
  border-bottom: solid 3px $disabled-border;
  border-left: solid 3px $disabled-border;
  box-shadow: 0px 5px 15px 0px rgba(66, 66, 66, 0.44);
  cursor: not-allowed;
}

.btnSkew.small {
  div {
    font-size: 0.8rem;
  }
}

.btnSkew.large {
  div {
    font-size: 1.5rem;
  }
}

.btnSkew.extraLarge {
  div {
    font-size: 2rem;
  }
}
