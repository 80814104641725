@import "../../../helpers/styleVariables/variables.scss";

@mixin sizeCircle {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin btnChoiceJoin {
  width: 50%;
  text-align: center;
  cursor: pointer;
  height: 50px;
  background-color: $accent-color;
  font-family: $font--primary;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s ease-in-out;

  p {
    text-align: center;
    color: $firth-color;
    font-size: 1.2rem;
    font-weight: 500;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.active {
    background-color: #491e7c;
  }
}

.roomManager {
  height: 100vh;
  background-size: cover;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    height: 100%;
    margin: auto;

    .characterChoice {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      font-family: $font--secondary;

      &__chevron {
        cursor: pointer;
        @include sizeCircle;
        z-index: 3;

        &.left {
          margin-left: 15px;
        }

        &.right {
          margin-right: 15px;
        }
      }

      &__img {
        height: 200px;
        width: 200px;
        border-radius: 100px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        position: relative;

        &__slider {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: 100%;
          padding-left: -50px;
          transition: transform 0.3s ease-in-out;
          pointer-events: none;

          img {
            height: 150%;
            width: 100%;
            min-width: 210px;
            margin: 0 50px;
            transform: translateX(-50px) translateY(100px);
            opacity: 0;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

            &.active {
              opacity: 1;
              transform: translateX(-50px) translateY(0);
            }

            &.forbidden {
              filter: saturate(0);
            }
          }
        }

        &__forbidden {
          position: absolute;
          bottom: -16%;
          left: 0;
          font-weight: bold;
          color: $accent-color;
          height: 30px;
          width: 100%;
          font-size: 0.8rem;
          text-align: center;
        }
      }
    }

    .form {
      margin-top: 30px;
      background-color: $secondary-color;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 25%;

      &__swapper {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &--create {
          @include btnChoiceJoin;
          border-top-left-radius: 5px;
          border-radius: 6px 0px 0px 0px;
        }
        &--join {
          @include btnChoiceJoin;
          border-radius: 0px 6px 0px 0px;
        }

        &--tournament {
          @include btnChoiceJoin;
          border-top-right-radius: 5px;
        }
      }

      &__wrapper {
        max-width: 400px;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
        box-sizing: content-box;

        &__pseudo {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 44px;
          @include inputForm;
        }

        &__code {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 44px;
          @include inputForm;
        }
        .form__wrapper__code {
          margin-bottom: 5%;
        }
        &__button {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            width: 100%;
            cursor: pointer;
          }
        }
      }
      .action {
        text-align: center;
        padding: 30px 0;

        &__connect {
          color: $fourth-color;
          text-decoration: none;
        }
      }
    }
  }
}
