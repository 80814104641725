@import '../../../helpers/styleVariables/variables.scss';

.general-ranking {
  .ranking-container {
    width: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    .ranking {
      width: 90%;
      height: 100%;
      position: relative;
      .user-position {
        padding-bottom: 15px;
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
      .other-positions {
        padding-top: 25px;
        padding-bottom: 15px;
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
      .rank {
        border-radius: 5px;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        background-color: $dashboard-subelement;
        overflow: hidden;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: white;
          width: 60%;
        }
        span {
          width: 40%;
          text-align: right;
          color: white;
        }
        .score {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0 10px;
        }
        .number {
          min-width: 55px;
          height: 100%;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background-color: #777777;
          font-weight: 500;
        }
      }
      .total-ranking {
        .rank {
          &:first-child {
            margin-top: 5px;
            .number {
              background-color: #e7c826;
            }
          }
          &:nth-child(2) {
            .number {
              background-color: #a7a7a7;
            }
          }
          &:nth-child(3) {
            .number {
              background-color: #aa7455;
            }
          }
        }
      }
      // .enemy {
      // }
      .current {
        background-color: #752cd1;
        width: 100%;
        margin: auto;
        height: 50px;

        .number {
          background-color: #571aa5;
          min-width: 75px;
        }
      }
      .player {
        height: 80px;
        background-color: $accent-color;
        .number {
          background-color: #571aa5;
        }
      }
    }
  }
}
