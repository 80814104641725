@import '../../../helpers/styleVariables/variables.scss';

.roulette {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__wrapper {
    height: 200px;
    width: 300px;
    margin: auto;
    border-radius: 10px;
  }

  &__slider {
    display: flex;
    flex-direction: column;
    margin: auto;
    transition: transform 2s ease-in-out;
  }

  &__mode {
    width: 100%;
    height: 180px;
    margin-top: 20px;
    transition: opacity 0.2s ease-in, transform 0.2s ease-in-out;
    opacity: 0;
    padding-top: 20px;

    &.active {
      opacity: 1;
      transform: scale(1.5);

      .roulette__mode__card {
        box-shadow: 0px 5px 15px 0px rgba(117, 44, 209, 0.44);
        border: 4px solid $accent-color;

        p {
          font-weight: 600;
          position: absolute;
          left: 0;
          bottom: 0;
          background: #752cd1;
          clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
          padding: 5px 40px 5px 10px;
        }
      }
    }

    &.previous {
      opacity: 0.3;

      .roulette__mode__card {
        background: linear-gradient(to bottom, #303030, #fff);
      }
    }

    &__card {
      padding: 15px;
      width: 100%;
      height: 100%;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s ease-in-out;
      position: relative;
      border-radius: 10px;
      overflow: hidden;

      p {
        transition: color 0.2s ease-in-out;
        font-size: 1rem;
        z-index: 2;
        color: white;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 0;
      }
    }
  }
}

.titleRoulette {
  text-transform: uppercase;
  text-align: center;
  color: #f5f5f5;
  font-family: 'Fauna Pro';
  position: absolute;
  top: 30%;
  right: 44%;
}

.roulette_room-id {
  color: white;
  font-size: 14px;
  padding-top: 35px;
  opacity: 0.4;
  font-weight: 500;
}
