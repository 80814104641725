$primary-color: #752cd1;
$secondary-color: #414141;
$third-color: #303030;
$fourth-color: #acacac;
$firth-color: #f5f5f5;

@mixin flexmid {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexBetween {
  display: flex;
  justify-content: space-between;
}

@keyframes fade-bottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.containerRules {
  position: fixed;
  top: 0;
  z-index: 5;
  height: 100vh;
  @include flexmid();
  color: #f5f5f5;
  * {
    font-weight: 300;
  }
  .globalRules {
    padding: 4%;
    border-radius: 5px;
    overflow-y: scroll;
    width: 80%;
    height: 85%;
    background-color: $third-color;
    opacity: 0;
    transform: translateY(20px);
    animation: fade-bottom 0.3s ease-in-out forwards;
    position: relative;
  }

  .rulesTitle {
    height: auto;
    display: flex;
    justify-content: space-between;

    #closeRules {
      position: absolute;
      right: 3%;
      top: 5%;
      width: 3.5%;
      object-fit: contain;
      cursor: pointer;
    }
  }

  .leftrightRules {
    font-size: 0.8vw;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    h2 {
      margin-bottom: 2%;
    }
    .leftRules {
      width: 45%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      p {
        margin-bottom: 3%;
      }
    }

    .rightRules {
      width: 45%;

      .playModes {
        p {
          margin-bottom: 10px;
        }
      }

      ul {
        margin-left: 5%;
      }
    }

    .controls {
      flex-direction: column;
      .inputControl {
        display: flex;
        align-items: center;
        height: 35px;
        margin-bottom: 10px;
      }
      .chevron {
        border: 1px solid #fff;
        padding: 3px;
        height: 30px;
        width: 30px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 3px;
      }
      .textChevron {
        font-size: 0.9rem;
        color: $firth-color;
        display: flex;
        align-items: center;
        height: 100%;
        span {
          padding: 0px 5px;
          height: 30px;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 7px;
          border: 1px solid #fff;
        }
      }
    }
  }

  ul {
    font-family: "poppins";
  }
}

p {
  font-family: "poppins";
  font-weight: 300;
  font-weight: 400;
  color: #f5f5f5;
}

.howPlay {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#lastp {
  margin-top: 3%;
}

.cardsAD {
  p {
    margin-top: 3%;
  }
}

.bold {
  font-weight: bold;
}

span.weightSemibold {
  font-weight: 500;
}
