.capacities {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 210px;
  position: relative;

  &__child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100px;
    height: 100%;
    border-radius: 0.5rem;

    &.isLoading {
      background-color: transparent;

      img {
        filter: saturate(0);
      }
    }

    &:nth-child(1) {
      background-color: rgb(255, 82, 27);

      &.isLoading {
        background-color: rgb(102, 38, 18);
        }
    }

    &:nth-child(2) {
      margin-top: 1rem;
      background-color: #43bede;

      &.isLoading {
        background-color: #11343d;
      }
    }

    .capacity {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      img {
        width: 90%;
        height: 90%;
        object-fit: cover;
        z-index: 1;
        border-radius: 0.3rem;
        transition: filter 0.1s ease-in-out;
      }

      .loader {
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 0;
        border-radius: 0.5rem;
      }
    }
  }

  &__text {
    position: absolute;
    right: 8rem;
    top: 0;
    bottom: 0;
    width: max-content;
    margin: auto;
    height: fit-content;
    opacity: 1;
    transform: translateX(0);
    transition: all 0.3s ease-in-out;
    animation: come-in 3s ease-in-out forwards;

    &.loading {
      animation: unset;
      opacity: 0;
      transform: translateX(20%);
    }

    &.ulti {
      color: rgb(255, 82, 27);
    }

    &.shield {
      color: #43bede
    }
  }
}

@keyframes reloader-blue {
  0% {
    height: 0%;
    background-color: #11343d;
  }
  100% {
    height: 100%;
    background-color: rgba(white, 0.8);
  }
}

@keyframes reloader-red {
  0% {
    height: 0%;
    background-color: rgb(102, 38, 18);
  }
  100% {
    height: 100%;
    background-color: rgba(white, 0.8);
  }
}

@keyframes line-reloader {
  0% {
    width: 0%;
    background-color: red;
  }
  100% {
    width: 100%;
    background-color: #65c465;
  }
}

@keyframes come-in {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  10% {
    opacity: 1;
    transform: translateX(0);
  }

  90% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20%);
  }
}