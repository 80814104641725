#game {
  margin-top: 89px;
  background-color: rgb(24, 0, 82);
}

.overlay2mort {
  display: flex;

  div {
    width: 116.6px;
    height: 10px;
    background: rgba(255, 0, 0, 0.199);
    border: solid 1px grey;
  }
}

.canvas {
  position: relative;
  height: 100%;
  width: 100%;
  outline: none;
  .canvas_content {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }
}
