@import '../../../helpers/styleVariables/variables.scss';

.error {
  width: fit-content;
  padding: 1rem 2rem;
  text-align: center;
  position: fixed;
  left: 2rem;
  bottom: 2rem;
  background-color: #ff0033;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  z-index: 5;

  &.visible {
    animation: fade-bottom 0.3s ease-in-out forwards;
  }

  &__warning {
    width: 20px;
  }

  &__close {
    background-color: #f20505;
    border: 2px solid white;
    border-radius: 100px;
    padding: 0.3rem 1rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 25px;
    }

    p {
      padding-left: 0.3rem;
    }
  }

  &__message {
    color: white;
    margin: 0 1rem;
  }
}