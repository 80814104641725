@import "../../../helpers/styleVariables/variables.scss";

.parties {
  width: 32%;
  height: 100%;
  h2 {
    color: #fff;
  }
  &-div {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    &-element {
      width: 90%;
      position: relative;
      margin-bottom: 100px;
      display: flex;
      justify-content: space-between;
      .you,
      .ennemy {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        height: 80px;
        background: $dashboard-subelement;
        padding: 10px;
        img {
          height: 50px;
          width: 50px !important;
          object-fit: contain;
          width: auto;
          background-color: #414141;
          border-radius: 50px;
        }
        p {
          color: white;
          &:first-child {
            font-weight: 500;
            font-size: 1.1vw;
          }
          &:last-child {
            font-size: 0.7vw;
          }
        }
        &:first-child {
          img {
            margin-right: 10px;
          }
        }
        &:last-child {
          justify-content: flex-start;
          text-align: right;
          flex-direction: row-reverse;
          img {
            margin-left: 15px;
          }
        }
      }
      .you {
        left: 0;
        clip-path: polygon(0 0, 58% 0, 38% 100%, 0 99%);
        border-radius: 5px 0px 0px 5px;
      }
      .ennemy {
        right: 0;
        clip-path: polygon(62% 0, 100% 0%, 100% 100%, 42% 100%);
        border-radius: 0px 5px 5px 0px;
      }
      .winner {
        background-color: $accent-color !important;
      }
    }
  }
}
