@keyframes goDownOpacity {
  0% {
    transform: translateX(-200%) scale(0);
    opacity: 0;
  }
  50% {
    transform: translateX(0) scale(1);
    opacity: 0.3;
  }
  100% {
    transform: translateX(200%) scale(0);
    opacity: 0;
  }
}

.loading_timer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #ffffff;

  &__title {
    text-align: center;
    font-size: 4rem;
    width: fit-content;
  }

  &__counter {
    position: relative;
    &__time {
      width: 100%;
      text-align: center;
      font-size: 8rem;
    }
  }

  &__bg {
    width: fit-content;
    height: fit-content;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: fixed;
    font-size: 40rem;
    animation: goDownOpacity 1s ease-in-out infinite
  }
}
