.left-dash,
.bottom-dash {
  position: absolute;
  opacity: 0.5;
}

.bottom-dash {
  bottom: 7%;
  right: 0;
  width: 80%;
}

.left-dash {
  left: 7%;
  top: -10%;
  height: 100%;
}
